<script setup>
// 登录 开始
// -----------------------------------------------------------------------------------
import {useStore} from "vuex";
import {getCurrentInstance, onMounted, reactive, ref} from "vue";
import {message} from "ant-design-vue";
import router from "@/router";

const store = useStore();
const {proxy} = getCurrentInstance()
// import UploadChangeParam from 'ant-design-vue';
// 登录页面
// const loginCheck = ref(false)
// const uploadDic = ref(false);
const loginForm = reactive({
  name: '',
  password: '',
});
let token = ""
let user_info = ref({})

onMounted(async () => {
  token = sessionStorage.getItem('token');
  if (token) {
    store.commit("set_token", token);
  }
  // console.log(proxy)
  try {
    const response = await proxy.$api.post('/get_user_info');

    user_info.value = response.data;
    await router.push({name: 'Dashboard'})

  } catch (error) {
    console.error(error);
      if (error.response && error.response.status === 401) {
        console.warn('未授权，处理 401 错误');
        sessionStorage.removeItem('token')
        // 例如，跳转到登录页
      } else {
        console.error('其他错误：', error);}
  }
});

const loginAdmin = (data) => {
  // console.log(data)
  if (data['status'] === "success") {
    message.success('登录成功！');
    // 设置cookie
    store.commit("set_token", data['token']);
    // get_data()
    user_info.value = data.user;
    token = data.token
    if (token) {
      store.commit("set_token", token)
    }
    // loginCheck.value = true
    router.push({name: 'Dashboard'})
    // 'plainTextToken'
  } else {
    message.error(data['status']);
  }
}
const onLoginFinish = async () => {
  message.info("正在登录...")
  try {
    // console.log(loginForm.name)
    const response = await proxy.$api.post('/login_user', {name: loginForm.name, password: loginForm.password});
    loginAdmin(response.data);
  } catch (error) {
    if (error.response && error.response.status === 400) {
      message.error(error.response.data.state)
    } else {
      message.error('意外发生了，请联系管理员。')
    }
  }
};
const onLoginFinishFailed = (errorInfo) => {
  message.error("登录失败，原因：" + errorInfo)
};

// 登录 结束
// -----------------------------------------------------------------------------------
</script>

<template>
  <a-row style="height: 100%" align="middle">
    <a-col :xs="{ span: 22, offset: 1 }" :md="{ span: 18, offset: 3 }" :xl="{ span: 6, offset: 9 }">
      <a-typography-title class="title">
        吉康医疗后台管理系统
      </a-typography-title>
      <a-form
          :model="loginForm"
          name="basic"
          autocomplete="off"
          @finish="onLoginFinish"
          @finishFailed="onLoginFinishFailed"
      >
        <a-form-item
            name="name"
        >
          <a-input v-model:value="loginForm.name" placeholder="请输入邮箱"/>
        </a-form-item>

        <a-form-item
            name="password"
            :rules="[{ required: true, message: '请输入密码!' }]"
        >
          <a-input-password v-model:value="loginForm.password" placeholder="请输入密码"/>
        </a-form-item>

        <!--                <a-form-item name="remember" :wrapper-col="{ offset: 8, span: 16 }">-->
        <!--                    <a-checkbox v-model:checked="loginForm.remember">记住我</a-checkbox>-->
        <!--                </a-form-item>-->

        <a-form-item>
          <a-button type="primary" html-type="submit" style="width: 100%">登录</a-button>
        </a-form-item>
      </a-form>

      <a-typography-paragraph type="secondary" style="text-align: center">
        Copyright © 2024 吉康医疗
      </a-typography-paragraph>
    </a-col>
  </a-row>
</template>

<style scoped>
.title {
  font-size: 28px;
  color: rgba(0, 0, 0, .85);
  font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
  font-weight: 600;
  position: relative;
  top: 2px;
  text-align: center;
  margin: 20px 0;
}

#app {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  background: #f5f5f5;
}

#index,
#index .a-layout .ant-layout-sider {
  width: 100%;
  height: 100%;
}

.steps-content {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 20px;
}

.steps-action {
  display: flex;
  justify-content: right;
  margin-top: 24px;
}

[data-theme='dark'] .steps-content {
  background-color: #2f2f2f;
  border: 1px dashed #404040;
}

.content {
  height: 38px;
  margin-top: 4px;
  margin-bottom: 0;
  overflow: hidden;
  font-size: 30px;
  line-height: 38px;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
}

.logo {
  /*height: 64px;*/
  /*background: rgba(255, 255, 255, 0.2);*/
  /*margin: 16px;*/
  /*height: 80px;*/
  text-align: center;
  user-select: none; /* 标准语法 */
  -moz-user-select: none; /* Firefox */
  -webkit-user-select: none; /* Chrome/Safari */
  -ms-user-select: none; /* IE/Edge */
}

/*.logo img {*/
/*    height: 100%; !* 或者其他尺寸，根据需要调整 *!*/
/*}*/
</style>
