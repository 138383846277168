import { createStore } from 'vuex';
import axios from 'axios'
export default createStore({
    state: {
        token: ''
    },
    getters: {},
    mutations: {
        set_token(state, token) {
            state.token = token;
            sessionStorage.setItem('token', token)
            axios.defaults.headers.common.Authorization = `Bearer `+token;
        },
        clearUserData () {
            sessionStorage.removeItem('token')
            location.reload()
        }
    },
    actions: {},
    modules: {}
});
